import React from 'react';
import styled from 'styled-components';

const CardHorizontalStyles = styled.article`
  background: var(--orange);
  margin-bottom: 1rem;
  width: 100%;
  min-height: 25vh;
  display: flex;
  flex-flow: column;
  position: relative;

  @media (min-width: 768px) {
    flex-flow: row;
  }

  &.is-linked {
    &:hover {
      background: #ad5d23;
    }
  }

  &:nth-of-type(2) {
    background: var(--teal);
    &.is-linked {
      &:hover {
        background: #247574;
      }
    }
  }

  &:nth-of-type(3) {
    background: var(--violet);
    &.is-linked {
      &:hover {
        background: #2d3073;
      }
    }
  }

  &:nth-of-type(4) {
    background: var(--green);
    &.is-linked {
      &:hover {
        background: #0b4e28;
      }
    }
  }

  a {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &:focus {
      outline: 0 !important;
      
      &::before {
        outline: 4px solid var(--violet);
        outline-offset: 4px;
      }
    }
  }

  .image-wrapper {
    max-height: 100%;
    order: 1;

    @media (min-width: 768px) {
      flex-basis: 30%;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .content {
    padding: 2rem;
    order: 2;

    @media (min-width: 768px) {
      flex-basis: 70%;
      flex-grow: 1;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }

  h2,
  p,
  a {
    color: var(--white);
  }

  p {
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

function IsLinkedCard({ link, name }) {
  if (link) {
    return (
      <h2>
        <a href={link}>{name}</a>
      </h2>
    );
  }
  return <h2>{name}</h2>;
}

function SingleHorizontalCard({ card }) {
  const { name, description, link, image } = card || {};
  return (
    <CardHorizontalStyles className={link && 'is-linked'}>
      <div className="content">
        {<IsLinkedCard link={link} name={name} />}
        {description && <p>{description}</p>}
      </div>
      {image && (
        <div className="image-wrapper">
          <img src={image.image.asset.url} alt={image.alt} />
        </div>
      )}
    </CardHorizontalStyles>
  );
}

export default function CardListHorizontal({ cards }) {
  return (
    //   Can use as many as they want
    // use either all images, or none
    //  Up to 4 (max)
    // use either all images, or none
    // accepts for group: as many cards as they want., or none.
    // accepts, for each card: heading, link, long text, optional image
    <div>
      {cards.map((card) => (
        <SingleHorizontalCard key={card.id} card={card} />
      ))}
    </div>
  );
}

// Ill want to import the following for horixontal cards:
// horizontalCards {
//     id
//     name
//     description
//     link
//     image {
//       image {
//         asset {
//           url
//         }
//       }
//       alt
//     }
//   }
