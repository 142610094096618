import { graphql } from 'gatsby';
import React from 'react';
import AccessibleImage from '../blocks/AccessibleImage';
import TextSection from '../blocks/TextSection';
import CardListHorizontal from '../blocks/CardListHorizontal';
import SEO from '../components/SEO';

export default function AboutPage(props) {
  const { data = {} } = props;
  const { name, image, content, horizontalCards } = data.about.nodes[0] || {};
  return (
    <>
      <SEO title="About" />
      {image && (
        <div className="hero hero--top">
          <AccessibleImage image={image} />
        </div>
      )}
      {name && <h1>{name}</h1>}
      {content && <TextSection content={content} />}
      {horizontalCards?.length > 0 && (
        <CardListHorizontal cards={horizontalCards} />
      )}
    </>
  );
}

export const query = graphql`
  query AboutQuery {
    # renames query
    about: allSanityAboutpage {
      nodes {
        name
        image {
          image {
            asset {
              url
            }
          }
          alt
        }
        content {
          name
          _rawContent(resolveReferences: { maxDepth: 10 })
          link {
            link
            url
          }
          link2 {
            url
            link
          }
        }
        horizontalCards {
          id
          name
          description
          link
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
      }
    }
  }
`;
